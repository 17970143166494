import React from 'react';
import './BannerOne.scss';

const BannerOne = () => {


  return (
    <>
      <div className='BannerOne'>
      </div>
    </>
  )
}

export default BannerOne
